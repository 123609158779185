
<template>
  <div class="banner">
    <img
      src="~@/assets/banner.jpg"
      alt="为最美水利工程20强点赞"
    />
  </div>
</template>

<script>
export default {
  name: 'banner-component'
}
</script>

<style>
  .banner {
    width: 100%;
    overflow: hidden;
    font-style: 0;
    
  }

  .banner img {
    width: 100%;
  }
</style>