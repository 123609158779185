import { createApp } from 'vue'
import 'loaders.css'
import 'weui'
import App from './App.vue'

const calcViewport = () => {
  const {
    availWidth,
    width,
  } = window.self === window.top
    ? window.screen
    : {
      width: 375,
      availWidth: 375,
    }
  const { clientWidth: docWidth } = document.documentElement

  const clientWidth = Math.min(
    availWidth,
    width,
    docWidth,
  )
  const $mate = document.querySelector('#viewport')
  const scale = (clientWidth || 375) / 375
  $mate.content = `width=${ 375 }, initial-scale=${ scale }, maximum-scale=${ scale }, user-scalable=no`
}

calcViewport()

createApp(App).mount('#app')
