<template>
  <div class="vote-component">
    <div class="title" v-if="views">
      <div class="title-item">
        访问量：<span>{{ format(views.view_number) }}</span>
      </div>
      <div class="title-item">
        投票数：<span>{{ format(views.vote_number) }}</span>
      </div>
      <div class="title-item">
        参选数：<span>{{ format(views.join_number) }}</span>
      </div>
    </div>

    <div class="content">
      <div class="vote-item" v-for="item in items"
        :key="item.id"
      >
        <div
          @click.prevent.stop="clickHandler(item)"
        >
          <div class="vote-cover" v-if="item.img"
            :style="{ backgroundImage: `url(//sl.codonas.cn/${ item.img })` }"
          ></div>
          <div class="vote-name">
            {{ item.id }}.{{ item.name }}
          </div>
          <div class="vote-desc">
            当前票数：{{ item.total_votes }}票
            <br>
            查看详情>>
          </div>
        </div>

        <label :class="`vote-button ${ selected.includes(item.id) ? 'active' : '' }`"
          @click.prevent.stop="selectClickHandler(item.id)"
        >
        </label>
      </div>
    </div>

    <div class="button"
      @click.stop.prevent="submitHandler"
    >
      <img src="~@/assets/submit.png">
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import weui from 'weui.js'
export default {
  name: 'vote-component',
  props: ['items'],
  data () {
    return {
      views: window.page_config,
      selected: [],
    }
  },
  methods: {
    format (value) {
      if (value > 10000) {
        return Math.floor(value / 1000) / 10 + 'w'
      }

      if (value > 1000) {
        return Math.floor(value / 100) / 10 + 'k'
      }

      return value
    },
    clickHandler (detail) {
      this.$emit('detail', detail)
    },
    selectClickHandler (id) {
      const index = this.selected.findIndex(_ => _ === id)
      
      if (index >= 0) {
        // 移除选项
       this.selected.splice(index, 1)
      } else {
        if (this.selected.length >= 10) {
          weui.alert('最多只能选择10个候选工程，点击已选工程可取消选择')
          return
        }
        // 新增选择
        this.selected.push(id)
      }
     
      this.$emit('select', this.selected)
    },
    async submitHandler () {
      const data = this.selected
      if (!data || data.length !== 10) {
        weui.alert('请选择10个候选工程一起投票')
        return
      }

      const loading = weui.loading('投票中')
      
      try {
        const response = await axios.post('http://sl.codonas.cn/vote', {
          ids: data,
        }).then(r => r.data)

        const { success, message } = typeof response === 'object' ? response : {}

        if (!success) {
          weui.alert(message || '投票已结束')
          loading.hide()
          return
        }
        this.responseSuccess()
      } catch (error) {
        console.error(error)
        weui.alert('投票已结束')
      }

      loading.hide()
    },
    responseSuccess () {
      this.selected = []
      this.$emit('select')
      this.$emit('update')
      weui.alert('投票成功')
    },
  },
  beforeUnmount () {
    this.$emit('select')
  }
}
</script>

<style>
.vote-component .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.vote-component .title-item {
  display: flex;
  align-items: center;
  overflow: hidden;
  color: #878787;
  font-size: 14px;
  font-weight: lighter;
}

.vote-component .title-item span {
  font-size: 16px;
  color: #000;
}

.vote-component .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;
}

.vote-component .vote-item {
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 142px;
  margin-bottom: 24px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #feedcc;
}

.vote-component .vote-item > div {
  flex: 1;
  margin-bottom: 12px;
}

.vote-component .vote-cover {
  height: 70px;
  margin-bottom: 12px;
  overflow: hidden;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #fff;
}

.vote-component .vote-name {
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  letter-spacing: 2px;
  font-size: 14px;
}

.vote-component .vote-desc {
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  color: #676767;
  font-size: 14px;
  line-height: 22px;
  font-weight: lighter;
}

.vote-component .vote-button {
  display: block;
  margin-bottom: 12px;
  margin-left: 6px;
  width: 64px;
  height: 20px;
  overflow: hidden;
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("~@/assets/checkout.png");
}

.vote-component .vote-button.active {
  background-image: url("~@/assets/checkouted.png");
}

.vote-component .button {
  width: 120px;
  margin-left: auto;
  margin-right: auto;
}

.vote-component .button img {
  width: 100%;
}
</style>