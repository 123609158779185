<template>
  <div class="rank">
    <div class="rank-item"
      v-for="(rank) in items"
      :key="rank.id"
    >
      <div
        class="rank-icon"
        :class="rankCol.indexOf(rank.total_votes) < 3 ? `active-${ rankCol.indexOf(rank.total_votes) }`: ''"
      >
        {{ rankCol.indexOf(rank.total_votes) + 1}}
      </div>
      <div class="rank-name">
        {{ rank.name }}
      </div>
      <div class="rank-num">
        {{ rank.total_votes }}票
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'tab-component',
  props: ['items'],
  computed: {
    rankCol () {
      return (this.items || []).map(
        ({ total_votes }) => (total_votes)
      )
    },
    // indexCol () {
    // },
  },
  mounted () {
    console.log(this.rankCol)
  }
}
</script>

<style>
.rank {
  padding: 24px 0;
}


.rank-item {
  display: flex;
  align-items: center;
  height: 42px;
  margin-bottom: 6px;
  padding-left: 24px;
  padding-right: 30px;
  overflow: hidden;
}

.rank-icon {
  flex-shrink: 0;
  margin-right: 12px;
  width: 18px;
  height: 26px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  font-weight: lighter;
}

.rank-icon.active-0 {
  background-image: url("~@/assets/1.png");
  font-size: 0;
}

.rank-icon.active-1 {
  background-image: url("~@/assets/2.png");
  font-size: 0;
}

.rank-icon.active-2 {
  background-image: url("~@/assets/3.png");
  font-size: 0;
}

.rank-name {
  flex: 1;
  overflow: hidden;
  font-size: 15px;
  color: #000;
  font-weight: lighter;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}

.rank-num {
  flex-shrink: 0;
  margin-left: 24px;
  overflow: hidden;
  color: #aa0528;
  font-size: 14px;
  font-weight: lighter;
}

</style>