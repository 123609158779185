<template>
  <div :class="`component ${ affix ? 'affix' : '' }`">
    <Affix
      @change="affixHandler"
    >
      <div class="tab">
        <div
          v-for="menu in menuList"
          :class="menu == current ? 'tab-item active' : 'tab-item'"
          :key="menu"
          @click="changeHandler(menu)"
        >
          {{ menu === 'vote' ? `投票${ selectList && selectList.length ? `(已选${selectList.length})` : '' }` : '排行榜' }}
        </div>
      </div>
    </Affix>
    <slot />
  </div>
</template>

<script>
import Affix from '@/components/affix'
export default {
  name: 'tab-component',
  props: ['current', 'selectList'],
  data () {
    return {
      affix: false,
      menuList: ['vote', 'rank']
    }
  },
  methods: {
    changeHandler (menu) {
      this.$emit('change', menu)
    },
    affixHandler (affix) {
      console.log(affix)
      this.affix = affix
    }
  },
  components: {
    Affix,
  }
}
</script>

<style>
.component {
  width: 327px;
  overflow: hidden;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  border-radius: 24px;
  background-image: linear-gradient(#fdeee2, #ffffff);
  font-size: 6px;
  line-height: 22px;
  font-weight: bold;
}

.component .icp-affix {
  background-color: #fff;
}

.component .tab {
  display: flex;
  height: 38px;
  overflow: hidden;
  border-bottom: 2px solid #eeb36c;
}

.component .tab-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1;
  height: 38px;
  overflow: hidden;
  color: #000000;
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: lighter;
  transition: color 350ms, background-color 350ms;
}

.component .tab-item.active {
  color: #fff;
  background-color: #aa0528;
}

.component .tab-item.active::after {
  position: absolute;
  left: 50%;
  bottom: 0;
  content: '';
  width: 0;
  height: 0;
  overflow: hidden;
  border: 6px solid transparent;
  border-bottom-color: #eeb36c;
  transform: translateX(-50%) translateZ(0);
}
</style>