
<template>
  <div class="rule"
    @click.prevent.stop
  >
    <div class="title">
      <img src="~@/assets/rule-title.png" />
    </div>
    <div class="content">
      <p>
        为深入贯彻落实绿色发展理念，彰显党史学习教育水利特色，大力宣传水利改革发展成就，传递人水和谐理念，弘扬“江南红旗渠精神”“海塘精神”“抗台精神”，由浙江省水利厅主办，浙江省水利学会、浙江省水利信息宣传中心、浙江广播电视集团经济生活频道承办的寻找“最美水利工程”主题活动于今年3月22日正式启动。
      </p>
      <p>
        经有关水利工程管理单位自荐、省市水利部门和组委会遴选，20个“最美水利工程”候选对象脱颖而出。现启动“最美水利工程”公众评选环节， 广泛征求社会各界意见。请您根据候选对象安全可靠、综合效益、绿色生态、文化底蕴等情况，投票选出心目中的10个“最美水利工程”！
      </p>
      <p>
        “最美水利工程”名单经公众投票、专家评审等环节后向社会公布，敬请期待！
      </p>
    </div>
    <div class="title">
      <img src="~@/assets/vote-title.png" />
    </div>
    <div class="content">
      <p>
        每个微信账号每天可参与1次投票，每次选择10个候选工程一起投票。
      </p>
      <p>
        投票截止时间为2021年6月20日24点整。
      </p>
    </div>
    <!-- <div class="extra">
      <div class="button"
        @click="clickHandler"
      >
        投票规则
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  methods: {
    clickHandler () {
      this.$emit('click')
    }
  }
}
</script>

<style>
  .rule {
    padding: 24px 30px 20px;
    width: 327px;
    overflow: hidden;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    border-radius: 24px;
    background-image: linear-gradient(#fdeee2, #ffffff);
    font-size: 12px;
    line-height: 22px;
    font-weight: bold;
  }

  .rule .title {
    width: 150px;
    height: 25px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    font-size: 0;
  }

  .rule .content {
    margin-bottom: 20px;
  }

  .rule .content:last-child {
    margin-bottom: 0;
  }

  .rule .title img {
    width: 100%;
  }

  .rule p {
    text-indent: 2em;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, .9);
  }

</style>