<template>
  <BannerComponent />

  <main>
    <RuleComponent />

    <TabComponent
      :current="menu"
      :selectList="selectList"
      @change="tabChangeHandler"
    >
      <VoteComponent v-if="menu === 'vote'"
        :items="voteList"
        @detail="detailModelHandler"
        @select="selectHandler"
        @update="fetch"
      />
      <RankComponent v-else
        :items="rankList"
      />
    </TabComponent>
  </main>
  <transition name="fade">
    <VoteModel v-if="medelData"
      :detail="medelData"
      @close="closeModelHandler"
    />
  </transition>

  <img hidden src="~@/assets/checkouted.png" />
</template>

<script>
import axios from 'axios'
import weui from 'weui.js'
import BannerComponent from '@/components/banner'
import RuleComponent from '@/components/rule'
import TabComponent from '@/components/tab'
import VoteComponent from '@/components/vote'
import RankComponent from '@/components/rank'
import VoteModel from '@/components/voteModel'

export default {
  name: 'App',
  data () {
    return {
      ruleVisiable: undefined,
      // detailModelId
      medelData: undefined,
      // 当前tab
      menu: 'vote',
      selectList: undefined,
      rankList: [],
      voteList: [],
    }
  },
  methods: {
    showModelHandler () {
      this.ruleVisiable = true
    },
    hideModelHandler () {
      this.ruleVisiable = undefined
    },
    tabChangeHandler (menu) {
      this.menu = menu
    },
    selectHandler (selectList) {
      this.selectList = selectList
    },
    detailModelHandler (medelData) {
      this.medelData = medelData
    },
    closeModelHandler () {
      this.medelData = undefined
    },
    async getRankList () {
      this.rankList = await axios.get('http://sl.codonas.cn/rank').then(r => r.data)
    },
    async getVoteList () {
      this.voteList = await axios.get('http://sl.codonas.cn/projects').then(r => r.data)
    },
    async fetch () {
      const $loading = weui.loading()
      try {
        await Promise.all(
          this.getRankList(),
          this.getVoteList(),
        )
      } catch (error) {
        console.log(error)
      }

      $loading.hide()
    }
  },
  mounted () {
    this.fetch()
  },
  components: {
    BannerComponent,
    RuleComponent,
    TabComponent,
    RankComponent,
    VoteComponent,
    VoteModel,
  }
}
</script>

<style>
body, html {
  padding-bottom: 24px;
  background-color: #aa0528;
}

main {
  position: relative;
  top: -25px;
  min-height: 300px;
  overflow: hidden;
  background-color: rgba(170, 5, 40, .35);
}

main .rule {
  margin-bottom: 24px;
}

#app {
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to, .fade-leave-active {
  opacity: 0;
}

[hidden] {
  display: none;
}
</style>
