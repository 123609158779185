<template>
  <div>
    <div class="vote-model-component-transparent"></div>
    <div class="vote-model-component">
      <div class="vote-model-close"
        @click="closeHandler"
      ></div>
      <div class="vote-model-slide" v-if="detail && detail.imgs && detail.imgs.length">
        <div class="vote-model-slide-container"
          :style="{width: `${ detail.imgs.length * 100 }%`}"
        >
          <div class="vote-model-slide-item" v-for="item in detail.imgs"
            :style="{
              backgroundImage: `url(//sl.codonas.cn/${ item.url })`,
            }"
            :key="item"
          ></div>
        </div>
      </div>
      <div class="vote-model-title">
        {{ (detail || {}).name }}
      </div>

      <div class="vote-model-desc">
        {{ (detail || {}).description }}
      </div>
    </div>
  </div>
</template>

<script>
import BetterScroll from 'better-scroll'

export default {
  name: 'vote-model-component',
  props: ['detail'],
  methods: {
    initSlide () {
      if (this.$slide || !this.detail || !this.detail.imgs || !this.detail.imgs.length) {
        return
      }
      this.$slide = new BetterScroll('.vote-model-slide', {
        scrollX: true,
        scrollY: false,
        slide: {
          threshold: 100,
          loop: false,
        },
        momentum: false,
        bounce: false,
        stopPropagation: true
      })
    },
    closeHandler () {
      this.$emit('close')
    }
  },
  mounted () {
    this.initSlide()
  }
}
</script>

<style>

.vote-model-component-transparent {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, .5);
  z-index: 100;
}
.vote-model-close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  overflow: hidden;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("~@/assets/close.png");
}
.vote-model-component {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 32px 24px 20px;
  width: 327px;
  overflow: hidden;
  z-index: 101;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #fff;
  background-image: url("~@/assets/model-bg.jpg");
  background-position: bottom center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  transform: translate3d(-50%, -50%, 0);
}

.vote-model-component .vote-model-slide {
  width: 100%;
  height: 160px;
  margin-bottom: 12px;
  overflow: hidden;
  border-radius: 4px;
}

.vote-model-slide-container {
  display: flex;
  height: 160px;
  width: 200%;
}

.vote-model-slide-item {
  flex: 1;
  overflow: hidden;
  height: 160px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.vote-model-title {
  position: relative;
  display: inline-block;
  padding: 10px;
  margin-bottom: 12px;
  overflow: hidden;
  font-size: 18px;
  color: #000;
  /* font-weight: lighter; */
}

.vote-model-title::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 30px;
  height: 16px;
  border: 1px solid transparent;
  border-top-color: #aa0528;
  border-left-color: #aa0528;
}
.vote-model-title::after {
  position: absolute;
  content: '';
  right: 0;
  bottom: 0;
  width: 30px;
  height: 16px;
  border: 1px solid transparent;
  border-bottom-color: #aa0528;
  border-right-color: #aa0528;
}

.vote-model-desc {
  font-size: 14px;
  line-height: 24px;
  text-indent: 2em;
}
</style>